import React, {useContext, useEffect, useMemo, useState} from 'react';
import {observer} from "mobx-react";
import BlockTitle from "../_BlockTitle";
import styles from "./styles/StaffBlock.module.scss"
import CleverSlider from "../../../utilities/CleverSlider/CleverSlider";
import StaffCard from "./StaffCard";
import {blockObjectFactory} from "../../SchoolBlocks/blockUtils";
import {StoreContext} from "../../../../stores/StoreLoader";
import classNames from 'classnames';
import ClickableLink from "../../../utilities/ClickableLink";
import {withoutLocaleInURI} from "../../../../internationalization/i18nURI";
import {EditDropDownItem} from "../EditDropDown";
import FontAwesome from "../../../utilities/FontAwesome";

export function UserSlider(props: {
    users: IUser[],
    viewMoreCard?: JSX.Element,
}) {
    const {modalStore, interfaceStore, organizationStore, userStore} = useContext(StoreContext);
    const [disableArrowItems, setDisableArrowItems] = useState(4);

    useEffect(() => {
        if (interfaceStore.breakpoint === 'break-point-lg') {
            setDisableArrowItems(4);
        }
        if (interfaceStore.breakpoint === 'break-point-md') {
            setDisableArrowItems(3);
        }
        if (interfaceStore.breakpoint === 'break-point-sm') {
            setDisableArrowItems(2);
        }
        if (interfaceStore.breakpoint === 'break-point-xs') {
            setDisableArrowItems(5);
        }
    },[interfaceStore.breakpoint])

    const staffBlockSliderClassNames = classNames({
        [styles.staffBlockSlider4Items]: interfaceStore.breakpoint === 'break-point-lg',
        [styles.staffBlockSlider3Items]: interfaceStore.breakpoint === 'break-point-md',
        [styles.staffBlockSlider2Items]: interfaceStore.breakpoint === 'break-point-sm',
        [styles.staffBlockSliderMobile]: interfaceStore.breakpoint === 'break-point-xs',
    });

    return <CleverSlider
        totalSlides={props.users.length}
        sliderClassName={staffBlockSliderClassNames}
        containerClassName={styles.cleverSliderContainer}
        childSlideClassName={styles.childSlideContainer}
        trackClassName={styles.trackClassContainer}
        scrollArrowClassName={styles.scrollArrowClassName}
        disableArrowItems={disableArrowItems}
        height={260}
    >
        {props.users.map(staff => {
            const modalObj = {
                id: staff.id,
                type: "schoolBlocksPerson",
                blockObj: blockObjectFactory("person", {id: staff.id, blockModel: staff} as IPersonBlock),
            }
            return (
                <button key={staff.id} onClick={() => modalStore.addModal(modalObj)}>
                    <div className={styles.slideItem} data-columns={(4)}>
                        <ul>
                            <StaffCard staff={staff}></StaffCard>
                        </ul>
                    </div>
                </button>
            )
        })}
        {props.viewMoreCard}
    </CleverSlider>
}

const StaffBlock = observer((props: {
    blockObj: IStaffTerrace,
    aboveTheFold: boolean,
}) => {
    const {modalStore, interfaceStore, organizationStore, userStore} = useContext(StoreContext);
    const [query, setQuery] = useState('')

    const handleChange = (e) => {
        setQuery(e.target.value)
    }

    const staffMembers = useMemo(() => {
        return props.blockObj.staff_members.filter((staff) =>
            staff.fname?.toLowerCase().includes(query.toLowerCase()) ||
            staff.lname?.toLowerCase().includes(query.toLowerCase())
        )
    },[query])

    const viewMore = ["district", "school"].includes(organizationStore.currentOrganization.type) && staffMembers.length === 7



    function onClick () {
        modalStore.addModal({
            type: "syncGroupsModal",
        });
    }

    const staffBlockDropDownItems: EditDropDownItem[] = [
        {
            title: 'Add Staff',
            icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-plus"/>,
            onClick: onClick,
        },
    ]

    return (
        <article>
            <BlockTitle
                blockObj={props.blockObj}
                aboveTheFold={props.aboveTheFold}
                dropDownItems={(organizationStore.currentOrganization.type === 'department' ||
                    organizationStore.currentOrganization.type === 'group') && userStore.isAdmin && staffBlockDropDownItems}
            />
            <div className={styles.container}>
                {(interfaceStore.breakpoint === 'break-point-xs' && organizationStore.currentOrganization.type === 'department') &&
                    <div className={styles.searchContainer}>
                        <form onSubmit={e => { e.preventDefault() }}>
                            <input
                                type="search"
                                className={styles.searchbar}
                                value={query}
                                onChange={handleChange}
                                placeholder="Search Staff Members"
                            />
                        </form>
                    </div>}
                <UserSlider
                    key={query}
                    users={staffMembers}
                    viewMoreCard={(viewMore && organizationStore.currentOrganization.type !== 'department') ?
                        <ClickableLink href={withoutLocaleInURI("/staff")}>
                            <div className={`${styles.slideItem} ${styles.viewMore}`}>
                                <span className={styles.viewMoreText}>View More</span>
                            </div>
                        </ClickableLink>
                    : undefined}
                />
            </div>
        </article>
    );
})

export default StaffBlock;
